import Vue from 'vue'
import Vuelidate from 'vuelidate'

import App from '@/App'
import router from '@/router'
import store from '@/store'
import mixin from '@/mixin'

import './main.css'

Vue.component('avatar', () => import('vue-avatar'))

Vue.component('put', () => import('@/components/Put'))
Vue.component('delete', () => import('@/components/Delete'))
Vue.component('toggle', () => import('@/components/Toggle'))
Vue.component('tooltip', () => import('@/components/Tooltip'))

Vue.component('new-user', () => import('@/components/Forms/User/New'))
Vue.component('new-group', () => import('@/components/Forms/Group/New'))
Vue.component('new-instance', () => import('@/components/Forms/Instance/New'))
Vue.component('fqdn', () => import('@/components/Forms/Instance/Fqdn'))

Vue.component('context-menu', () => import('@/components/ContextMenu'))
Vue.component('data-table', () => import('@/components/DataTable'))
Vue.component('data-table-actions', () => import('@/components/DataTableActions'))
Vue.component('data-table-row-actions', () => import('@/components/DataTableRowActions'))
Vue.component('data-table-row-dropdown', () => import('@/components/DataTableRowDropdown'))

Vue.use(Vuelidate)

Vue.directive('away', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

Vue.prototype.$bus = new Vue()

Vue.mixin(mixin)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

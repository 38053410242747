import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// import store from '@/store'
import { isLoggedIn, can } from '@/utils/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "signin" */ '@/views/Auth/SignIn'),
    meta: {
      breadcrums: [{ name: 'Signin', path: '/' }],
      requiresAuth: false,
    },
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/App'),
    meta: {
      breadcrums: [{ name: 'Dashboard', path: '/' }],
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard'),
        meta: {
          requiresAuth: true,
          breadcrums: [{ name: 'Dashboard', path: '/' }],
        },
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/Users'),
        meta: {
          requiresAuth: true,
          breadcrums: [
            { name: 'Dashboard', path: '/' },
            { name: 'Users', path: null },
          ],
          permission: 'list_users',
        },
      },
      {
        path: '/groups-and-permissions',
        name: 'Groups and Permissions',
        component: () =>
          import(/* webpackChunkName: "groups-and-permissions" */ '@/views/GroupsAndPermissions'),
        meta: {
          requiresAuth: true,
          breadcrums: [
            { name: 'Dashboard', path: '/' },
            { name: 'Groups and Permissions', path: null },
          ],
          permission: 'list_groups',
        },
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () =>
          import(/* webpackChunkName: "groups-and-permissions" */ '@/views/Settings'),
        meta: {
          requiresAuth: true,
          breadcrums: [
            { name: 'Dashboard', path: '/' },
            { name: 'Settings', path: null },
          ],
          permission: 'settings',
        },
      },
    ],
  },
  {
    path: '/403',
    name: '403',
    component: () => import(/* webpackChunkName: "forbidden" */ '@/views/Forbidden'),
    meta: {
      breadcrums: [{ name: 'Dashboard', path: '/' }],
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error'),
    meta: {
      breadcrums: [{ name: 'Dashboard', path: '/' }],
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.API_URL,
  routes,
  linkExactActiveClass: 'bg-gray-200',
})

router.beforeEach((to, from, next) => {
  store.dispatch('setNewButton', { label: null, component: null })

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn()) {
      next({
        path: '/signin',
        params: { nextUrl: to.fullPath },
      })
    } else {
      if (to.matched.some(record => record.meta.permission)) {
        if (can(to.meta.permission)) next()
        else next('/403')
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router

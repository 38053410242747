import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistance from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    me: {
      name: 'Farrukh Ayyaz',
      mention: 'farry19',
    },
    alert: {
      show: false,
    },
    modal: { show: false, component: null },
    sidebar: false,
    page: {
      title: '',
    },
    newButton: {
      label: null,
      component: null,
    },
  },
  getters: {
    me: state => state.me,
    alert: state => state.alert,
    modal: state => state.modal,
    sidebar: state => state.sidebar,
    page: state => state.page,
    newButton: state => state.newButton,
  },
  mutations: {
    setMe: (state, payload) => (state.me = payload),
    setAlert: (state, payload) => (state.alert = payload),
    setModal: (state, payload) => (state.modal = payload),
    setSidebar: (state, payload) => (state.sidebar = payload),
    setPage: (state, payload) => (state.page = payload),
    setNewButton: (state, payload) => (state.newButton = payload),
  },
  actions: {
    setMe: ({ commit }, payload) => {
      commit('setMe', payload)
    },
    setAlert: ({ commit }, payload) => {
      commit('setAlert', payload)

      setTimeout(() => {
        commit('setAlert', { show: false })
      }, 3000)
    },
    setModal: ({ commit }, payload) => {
      commit('setModal', payload)
    },
    setSidebar: ({ commit }, payload) => {
      commit('setSidebar', payload)
    },
    setPage: ({ commit }, payload) => {
      commit('setPage', payload)
    },
    setNewButton: ({ commit }, payload) => {
      commit('setNewButton', payload)
    },
  },
  modules: {},
  plugins: [new VuexPersistance().plugin],
})

import decode from 'jwt-decode'
import _ from 'lodash'
import store from '@/store'

const VUEX_PERSIST_KEY = 'vuex'
const TOKEN = 'token'
const PERMISSIONS = 'permissions'

export const loginUser = data => {
  const { token, permissions } = data

  localStorage.setItem(TOKEN, token)
  localStorage.setItem(PERMISSIONS, JSON.stringify(permissions))
}

const getTokenExpirationDate = encodedToken => {
  let token = decode(encodedToken)
  if (!token.exp) {
    return null
  }

  let date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date
}

const isTokenExpired = token => {
  let expirationDate = getTokenExpirationDate(token)

  return expirationDate < new Date()
}

export const logoutUser = () => {
  clearAuthTokens()
  store.dispatch('setMe', {})
}

export const getAuthToken = () => {
  const authToken = localStorage.getItem(TOKEN)

  return authToken
}

export const clearAuthTokens = () => {
  localStorage.removeItem(VUEX_PERSIST_KEY)
  localStorage.removeItem(TOKEN)
}

export const isLoggedIn = () => {
  let authToken = getAuthToken()

  if (!!authToken && !isTokenExpired(authToken)) {
    return true
  } else {
    clearAuthTokens()
    return false
  }
}

export const can = permission => {
  const permissions = _.map(store.getters.me.permissions, 'perm_name')
  return _.includes(permissions, permission) && store.getters.me.active
}

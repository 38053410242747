import _ from 'lodash'
import moment from 'moment'

export const dateOrDash = dateStr => {
  return dateStr
    ? `
    <div class="flex justify-between space-x-1 font-light">
      <span class="pl-1 pr-2 inline-flex items-center text-xs leading-5 rounded-full bg-indigo-100 text-indigo-800">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        ${moment(dateStr).format('DD.MM.YYYY')}
      </span>
      <span class="pr-2 inline-flex items-center text-xs leading-5 rounded-full bg-indigo-800 text-indigo-100">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        ${moment(dateStr).format('HH.mm.ss')}
      </span>
    </div>`
    : '-'
}

export const encode = str => {
  var encoded = ''

  for (let i = 0; i < str.length; i++) {
    var a = str.charCodeAt(i)
    var b = a ^ 51
    encoded = encoded + String.fromCharCode(b)
  }

  return encoded
}

export const buildQuery = obj => {
  const filters = _.reduce(
    obj,
    (result, value, key) => {
      if (value) result = `${key}=${value}&${result}`
      return result
    },
    ''
  )

  return filters
}

export const humanize = str => {
  return moment(str).fromNow()
}

export const appearance = (status = null) => {
  const statuses = [
    {
      id: 0,
      option: 'Only Me',
      label: 'Only Me',
      icon: 'icon fa-lock',
    },
    {
      id: 1,
      option: 'Everyone in Community',
      label: 'Everyone',
      icon: 'icon fa-users',
    },
  ]

  return status !== null ? statuses[status] : statuses
}

const planExp = val => (val > 0 ? 'Active' : 'Expired')

export const diff = (str1, str2) => {
  const date1 = moment(str1)
  const date2 = moment(str2)

  return planExp(date1.diff(date2, 'days'))
}
